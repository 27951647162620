@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

@import "../../assets/styles/Colors.scss";
@import "../../assets/styles/Vars.scss";

.user {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
    width: 100%;
    padding-bottom: 25px;
    padding-left: 0px;
    padding-right: 0px;
    overflow: hidden;
}

.public_picture {
    width: 150px !important;
    height: 150px !important;
    min-width: 150px;
    min-height: 150px;
}

.user_name_wrapper,
.user_info_wrapper {
    flex: 1 0 100%;
}

.user_info_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 15px;
}

.name {
    font-weight: 600;
    line-height: 50px;
    font-size: 50px;
}

.current_job_title,
.member_since,
.nick {
    font-weight: 400;
    font-size: 16px;
    color: $disabled-text;
}

.not_published {
    & > path:first-child {
        fill: $grey-background;
    }
}

.description {
    font-weight: 400;
    font-size: 16px;
    white-space: pre-wrap;
    max-width: 100%;
    min-width: 150px;
}

.name_wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
}

.contact_wrapper.move_up {
    margin-top: -5px;
}

.contact_wrapper > .disabled {
    background-color: $disabled-bkg !important;
}

.picture_wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 40px;
    justify-content: center;
    align-items: center;

    margin-left: 225px;
}

.noAchievement:not(.noAchievement) {
    margin-left: auto;
}

.icon {
    width: 31.25px;
    height: 31.25px;
}

@include media-breakpoint-down(md) {
    .picture_wrapper {
        margin-left: auto;
        flex-wrap: wrap;
        gap: 25px;
    }
}
