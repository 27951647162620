@import '../../../assets/styles/Colors.scss';

.wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 8px;
    justify-content: flex-start;
    color: $black;

    & textarea {
        min-height: 130px;

        &:focus:not(.error):not(.disabled),
        &:active:not(.error):not(.disabled) {
            outline: none;
            border: 2px solid $pressed-green;
        }

        &:hover:not(.error):not(.disabled) {
            outline: none;
            border: 2px solid $hover-green;
        }
    }
    
    & textarea.disabled:focus,
    & textarea.disabled:active,
    & input.disabled:focus,
    & input.disabled:active {
        outline: none;        
    }

    & input {
        min-height: 46px;

        &:focus:not(.error):not(.disabled),
        &:active:not(.error):not(.disabled) {
            outline: none;
            border: 2px solid $pressed-green;
        }

        
        &:hover:not(.error):not(.disabled) {
            outline: none;
            border: 2px solid $hover-green;
        }
    }
}

.title {
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.item {
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    border: 1px solid #CED4DA;
    border-radius: 4px;

    &.disabled {
        cursor: default;
        color: $disabled-text;
    }

    &.error {
        outline: none;
        border: 2px solid $error-border-color;
    }

}

.error_txt {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: $error-text-color;
}

.input_wrapper {
    width: 100%;
    position: relative;
}

.check {
    position: absolute;
    right: 5px;
    top: 18px;
    width: 20px;
}
