@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../../assets/styles/Colors.scss";
@import "../../../assets/styles/Vars.scss";

.wrapper {
    border: 1px solid $border-color;
    border-radius: $border-radius-boxes-small;
    background-color: white;
    width: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
}

.section_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 50px;
}

.view {
    text-decoration: underline;
    color: $primary-green;
    cursor: pointer;

    &:hover {
        color: $hover-green;
    }
}

.badge {
    width: 31.25px;
}

@include media-breakpoint-down(sm) {
    .completed_date {
        display: none;
    }
}
