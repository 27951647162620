@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,500,600,700);
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500&display=swap");
@import "~bootstrap/scss/bootstrap";

@import "./assets/styles/Colors.scss";

body {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background-color: #e5e5e5;

  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: normal;
  font-stretch: normal;
  color: $black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.PublicProfile {
  width: 100%;
  min-width: 300px;
  position: relative;

  & .PublicProfile-body {
    max-width: 933px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 15px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 50px;

    @media (max-width: 955px) {
      padding-left: 25px;
      padding-right: 25px;
    }
  }

  & .PublicProfile-notifications {
    position: fixed;
    top: 100px;
    right: 7px;
    width: 350px;
    z-index: 1000;
  }
}
