.wrapper {
    gap: 15px;
    align-items: center;
    
    &.display_cursor {
        cursor: pointer;
    }

    & > .icon_wrapper > svg {
        transition: width 0.4s ease;
    }

    &.display_cursor:hover {
        & > .icon_wrapper > svg {
            width: 40px;
            height: 40px;
        }
    }
}

.icon_wrapper {
    width: 50px;
    height: 50px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.text_wrapper {
    gap: 5px;
    align-items: flex-end;
    font-size: 30px;
    line-height: 30px;

    &:hover {
        & > .text {
            text-decoration: underline;
        }
    }
}

.text_wrapper,
.wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.text {
    font-size: 18px;
    line-height: 20px;
}
