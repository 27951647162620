@import "../../assets/styles/Colors.scss";

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
}

.contact_wrapper.move_up {
    margin-top: -5px;
}

.contact_wrapper > .disabled {
    background-color: $disabled-bkg !important;
}
