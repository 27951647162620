@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

@import '../../assets/styles/Mixins.scss';
@import '../../assets/styles/Colors.scss';

$space-gap: 5px;

.wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: $space-gap;
    width: 100%;

}

.space_wrapper {
    @include borderBox(6px);
    flex: 1 1 calc(50% - $space-gap);    
    overflow: hidden;    
    display: flex;
    flex-direction: column;


    &.single_space {
        flex-direction: row;        
        max-height: 368px;

        & .description_wrapper {
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;

            & > div:first-child {
                max-height: 286px;
            }
        }
        & .picture {
            max-width: 50%;
        }
    }
}

.no_pic {
    text-align: center;

    width: 100%;
    height: 250px;
    min-width: 250px;
}

.picture {
    width: 100%;
    height: auto;
    object-fit: cover;
    min-width: 250px;
}

.description_wrapper {
    width: 100%;
    height: 100%;
    min-height: 90px;
    margin-bottom: 20px;
    padding: 20px 20px 0px 20px;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    overflow: hidden;    
    
    & > div:first-child {
        overflow-y: auto;
        max-height: 70px;
    }
}

.view {
    color: $primary-green;
    font-size: 18px;
    line-height: 14px;

    &:focus,
    &:hover {
        color: $hover-green;
    }

    &:active {
        color: $pressed-green;
    }
}


@include media-breakpoint-down(sm) {
    .description_wrapper {
        max-height: 90px;
    }

    .space_wrapper {
        flex: 1 1 100%;
        max-width: 100%;
        max-height: none;

        &.single_space {
            
            flex-direction: column;
    
            & .description_wrapper {
                flex-direction: row;
                align-items: flex-start;
                gap: 4px;
                max-height: 90px;
                & a {
                    align-self: center;
                }
            }
    
            & .picture {
                min-width: 100%;
                width: 100%;
            }
        }    
    }
}
