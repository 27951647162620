@import "../../assets/styles/Colors.scss";
@import "../../assets/styles/Vars.scss";

$breakpoint: 696px;

.wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;

    width: 100%;
    padding: 16px;

    border: 1px solid $border-color;
    border-radius: $border-radius-boxes-small;
    background-color: white;

    @media (max-width: $breakpoint) {
        align-items: flex-start;
    }
}

.body_wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 35px;
    flex: 1;

    @media (max-width: $breakpoint) {
        justify-content: flex-start;
    }
}

.group_wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 35px;
}

.discord_icon {
    width: 25px;
    height: 25px;

    @media (max-width: $breakpoint) {
        margin-top: 14px;
    }
}
