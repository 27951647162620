@import "./assets//styles//Colors.scss";

.PublicProfile {
  .PublicProfile-menu-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 25px;
  }

  .PublicProfile-contact-footer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    width: 100%;

    margin-bottom: 20px;
    margin-top: 145px;

    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }

  .PublicProfile-nabuse-btn {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-decoration: underline;

    margin-top: 30px;
    color: $black;

    &:active {
      color: $pressed-green;
    }

    &:focus,
    &:hover {
      color: $hover-green;
    }
  }

  .PublicProfile-no-user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
  }
}

body {
  height: 100vh;
  margin: 0 auto !important;
}
