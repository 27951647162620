@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

@import "../../assets/styles/Colors.scss";

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
}

.title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: $dark-text;
    opacity: 0.5;
}

.value {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: $dark-text;
}

@include media-breakpoint-down(sm) {
    .wrapper {
        align-items: flex-start;
    }
}
