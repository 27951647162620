:global {
    .single-notification > .progress {
        height: 8px;
        background-color: white;
        margin: 10px 0 0 0;

        &:not(.progress-success) {
            border: solid 1px black;
        }

        &.progress-success {
            border: solid 1px #04aa6d !important;
        }

        & > .progress-bar {
            height: 100%;
    
            &.bg-info {
                background-color: black;
            }
    
            &.bg-success {
                background-color: #04aa6d;
            }
        }
    }   
}


.single {
    padding: 13px 0 10px;
    position: relative;
}

.filename {
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 306px;
    overflow: hidden;
    display: block;
    padding-right: 26px;
}

.upload_success_icon {
    position: absolute;
    top: 9px;
    right: 6px;
}
