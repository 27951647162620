.wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 3px;
  width: 100%;
  background-color: white;
  border: 1px solid #e7e9eb;
  border-radius: 4px;
  padding: 16px;
}

.experienceContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.experienceItem {
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.experienceContent {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
}

.experienceDates {
  font-size: 0.875rem;
  color: #666;
  flex-shrink: 0; 
}

.experienceInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .experiencePosition {
    font-size: 1.25rem;
  }

  .experienceDetails {
    font-size: 0.875rem;
    color: #666;
  }

  .experienceDuties {
    margin-top: 8px;
    list-style: none;
    font-size: 0.75rem;

    li {
      white-space: pre-line;
    }
  }
}
