@import '../../assets/styles/Colors.scss';
@import '../../assets/styles/Mixins.scss';

.hover_effect {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;

    background: transparent;
    border-radius: 50%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    &.has_picture > div {
        opacity: 0;
    }
}

.trigger_layer {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;

    opacity: 0;
    border-radius: 50%;
    overflow: hidden;

    /* &:hover {
        cursor: pointer;
    } */
}

.popover {
    width: 100%;
    height: 100%;
}

.image {
    width: 100%;
    height: auto;
    opacity: 1;
    z-index: 0;

    &.fade_in {
        @include fadeIn(1.3s);
    }
    &.hide {
        position: absolute;
        right: -10px;
        top: 0px;
        opacity: 0;
    }
}

.image_spinner {
    width: 33px !important;
    height: 33px !important;
    border-width: 0.3em !important;
}

.profile_tmp_img {
    font-family: Source Sans Pro;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: 2px;
    cursor: default;
}

.error_text_loading_image {
    color: $error-text-color;
    text-align: center;
    width: 100%;
}

.profile_picture {
    width: 184px;
    height: 184px;
    background: #aaacb0;
    border-radius: 50%;
    position: relative;
    overflow: hidden;

    border: 1px solid #e7e9eb; // #282a35;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    &:hover:not(.not_editable):not(.disabled) {
        cursor: pointer;

        & .error_text_loading_image {
            // opacity: 0;
            display: none;
        }

        & > div > .hover_effect {
            transition: all 300ms;
            background: rgba(40, 42, 53, 0.6);
            //opacity: 1;

            &.has_picture > div {
                opacity: 1;
            }

            &.has_picture:not(.error) {
                opacity: 0.75;
            }

            /* &.has_picture.fade_in {
                animation: fadeIn 0.7s;
            } */

            & .pencil > svg > path {
                fill: $hover-green;
            }

            & .pencil_default {
                display: none;
            }

            & .pencil_hover {
                display: block;
            }
        }
    }
}


.loading,
.has_picture {
    background: transparent;
    //border: 1px #AAACB0 solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.not_editable {
    display: flex;
    align-items: center;
    justify-content: center;
}

    
.edit_text {
    margin-top: 25%;
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
}

.pencil {
    background: #ffffff;
    border: 1px solid #dee2e6;
    border-radius: 25px;
    width: 32px;
    height: 32px;

    display: flex;
    align-items: center;
    justify-content: center;

    &.disabled {
        background: $disabled-bkg;
        cursor: default;
    }

    & > .pencil_default {
        display: block;
    }

    & > .pencil_hover {
        display: none;
    }
}
