$primary-green: #04aa6d;
$hover-green: #059862;
$pressed-green: #03744b;
$disabled-bkg: #e2e8ea;
$disabled-text: #798488;

$primary-bkg: #e5e5e5;
$secondary-bkg: #fff;
$black: #282a35;
$white: #fff;

$tertiary-border: #e5e5e5;
$tertiary-bkg: #fff;
$tertiary-hover: #798488;

$destructive-bkg: #d9212c;
$destructive-hover: #c5212c;
$destructive-pressed: #a7212c;

$dark-text: #282a35;
$dark-text-hover: #333333;
$dark-text-pressed: #282a35;

$pink-bkg: #ffc0c7;
$pink-hover: rgb(244, 185, 192);
$pink-pressed: rgb(222, 169, 177);

$border-color: #e7e9eb;
$black: #282a35;
$grey-background: #798488;
$error-text-color: #a7212c;
$error-border-color: #c5212c;
