$color-primary: #04aa6d;
$color-secondary: #fff;
$color-tertiary: #798488;
$color-destructive: #d9212c;
$color-pink: #ffc0c7;
$color-dark: #282a35;

$border-width-small: 0.15em;
$border-width-medium: 0.2em;
$border-width-large: 0.75em;
$border-width-xlarge: 0.75em;

._spinner_round_even {
    border-style: solid;
    border-radius: 50%;
    display: inline-block;
    animation: spin 1.6s linear infinite;
    position: relative;
    box-sizing: border-box;

    &._spinner_color_primary {
        border-color: $color-primary !important;
        border-right-color: transparent !important;
        &::after,
        &::before {
            background: $color-primary;
        }
    }
    &._spinner_color_secondary {
        border-color: $color-secondary !important;
        border-right-color: transparent !important;
        &::after,
        &::before {
            background: $color-secondary;
        }
    }
    &._spinner_color_tertiary {
        border-color: $color-tertiary !important;
        border-right-color: transparent !important;
        &::after,
        &::before {
            background: $color-tertiary;
        }
    }
    &._spinner_color_destructive {
        border-color: $color-destructive !important;
        border-right-color: transparent !important;
        &::after,
        &::before {
            background: $color-destructive;
        }
    }
    &._spinner_color_pink {
        border-color: $color-pink !important;

        border-right-color: transparent !important;
        &::after,
        &::before {
            background: $color-pink;
        }
    }
    &._spinner_color_dark {
        border-color: $color-dark !important;
        border-right-color: transparent !important;
        &::after,
        &::before {
            background: $color-dark;
        }
    }

    &._spinner_size_small {
        width: 15px;
        height: 15px;
        border-width: $border-width-small;
    }

    &._spinner_size_medium {
        width: 23px;
        height: 23px;
        border-width: $border-width-medium;
    }
    &._spinner_size_large {
        width: 90px;
        height: 90px;
        border-width: $border-width-large;
        &::after {
            content: "";
            width: $border-width-large;
            height: $border-width-large;
            border-radius: 50%;
            position: absolute;
            right: 0px;
            top: 0px;
        }
        &::before {
            content: "";
            width: $border-width-large;
            height: $border-width-large;
            border-radius: 50%;
            position: absolute;
            right: 0px;
            bottom: 0px;
        }
    }
    &._spinner_size_xlarge {
        width: 150px;
        height: 150px;
        border-width: $border-width-xlarge;
        &::after {
            content: "";
            width: $border-width-xlarge;
            height: $border-width-xlarge;
            border-radius: 50%;
            position: absolute;
            right: 7px;
            top: 9px;
        }
        &::before {
            content: "";
            width: $border-width-xlarge;
            height: $border-width-xlarge;
            border-radius: 50%;
            position: absolute;
            right: 7px;
            bottom: 9px;
        }
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.lds_ring {
    display: inline-block;
    position: relative;

    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        //margin: 10%;
        border-radius: 50%;
        animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    }
    &._spinner_color_primary {
        div {
            border: 0.5em solid $color-primary;
            border-color: $color-primary transparent transparent transparent;
        }
    }
    &._spinner_color_secondary {
        div {
            border: 0.5em solid $color-secondary;
            border-color: $color-secondary transparent transparent transparent;
        }
    }
    &._spinner_color_tertiary {
        div {
            border: 0.5em solid $color-tertiary;
            border-color: $color-tertiary transparent transparent transparent;
        }
    }
    &._spinner_color_destructive {
        div {
            border: 0.5em solid $color-destructive;
            border-color: $color-destructive transparent transparent transparent;
        }
    }
    &._spinner_color_pink {
        div {
            border: 0.5em solid $color-pink;
            border-color: $color-pink transparent transparent transparent;
        }
    }
    &._spinner_color_dark {
        div {
            border: 0.5em solid $color-dark;
            border-color: $color-dark transparent transparent transparent;
        }
    }

    &._spinner_size_small {
        width: 15px;
        height: 15px;
        div {
            border-width: $border-width-small;
        }
    }
    &._spinner_size_medium {
        width: 25px;
        height: 25px;
        div {
            border-width: 0.2em;
        }
    }
    &._spinner_size_large {
        width: 90px;
        height: 90px;
        div {
            border-width: 0.6em;
        }
    }
    &._spinner_size_xlarge {
        width: 150px;
        height: 150px;
        div {
            border-width: $border-width-xlarge;
        }
    }

    div:nth-child(1) {
        animation-delay: -0.45s;
    }
    div:nth-child(2) {
        animation-delay: -0.3s;
    }
    div:nth-child(3) {
        animation-delay: -0.15s;
    }
}
