@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import '../../assets//styles/Colors.scss';
@import '../../assets//styles/Mixins.scss';

.body {
    padding-bottom: 50px !important;
}

.header {
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
}

.description {
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
    width: 70%;
    color: $black;
}

.body_wrapper {    
    @include borderBox();
    width: 100%;

    & form {
        width: 100%;
        padding: 40px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;    
        align-items: flex-start;
        gap: 40px;
    }
}

.confirm_btn {
    align-self: flex-end;
}

.section_wrapper {
    @include fadeIn();

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
}

.user_wrapper {
    width: 100%;

    display: flex;
    flex-direction: row; 
    gap: 24px;
}

.user_item {
    flex: 1 1 calc(100% - 24px);
}

.form_item {
    width: 100%;
}

@include media-breakpoint-down(md) {
    .user_wrapper {
        flex-direction: column;
    }

    .user_item {
        flex: 0 0 100%;
    }
}

.txt_recieved {
    width: fit-content;
}
