.modal_wrapper {
    display: flex;
    flex-wrap: nowrap;
    gap: 25px;
    flex-direction: column;
}

.name_wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 23px;
    align-items: center;

    @media (max-width: 575.98px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.public_picture {
    width: 95px !important;
    height: 95px !important;
    min-width: 95px;
    min-height: 95px;
}

.name {
    font-weight: 600;
    font-size: 26px;
    line-height: 33px;
}

.sub_header {
    width: 100%;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
}

.content_txt {
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.header_wrapper {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.section {
    overflow: hidden;
}
