.cls_1 {
    fill: #04aa6b;
}
.cls_2 {
    font-size: 23px;
}
.cls_2,
.cls_3,
.cls_4 {
    fill: #fff;
}
.cls_2,
.cls_3 {
    font-family: "RobotoMono-Medium", "Roboto Mono";
    font-weight: 500;
}
.cls_3 {
    font-size: 20.08px;
}
