.wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 3px;
  width: 100%;
  background-color: white;
  border: 1px solid #e7e9eb;
  border-radius: 4px;
  padding: 16px;
}

.skillsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
