@import "../../assets/styles/Colors.scss";

.wrapper {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    gap: 1px;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.badges_wrapper {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
}

.type_text {
    height: 15px;
    font-weight: 400;
    font-size: 16px;
    color: $disabled-text;
}
