.wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;

    width: auto;
}

.item {
    flex: 0 0 100%;
}
