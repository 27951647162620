@import "./Vars.scss";
@import "./Colors.scss";

@mixin borderBox($border-radius: $border-radius-boxes) {
    border: 1px solid $border-color;
    transition: border-color $transition-speed;
    border-radius: $border-radius;
}

@mixin smallBorderBox {
    border: 1px solid $border-color;
    transition: border-color $transition-speed;
    border-radius: $border-radius-boxes-small;
}

@mixin fadeIn($speed: $transition-speed) {
    opacity: 1;
    animation: fadeIn $speed;
}

@mixin fadeOut($speed: $transition-speed) {
    animation: fadeOut $speed;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
