@import "../../../assets/styles/Colors.scss";

.tag_wrapper {
    cursor: default;
    background: #ffffff;
    border: 1px solid $border-color;
    border-radius: 25px;
    min-width: 70px;
    padding: 7px 15px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    color: $black;
}
