// @import "../../Asset/Style/Util/MixedUtil.scss";
@mixin setBorderRadius($radius: 5px) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}

.popover_menu_wrp {
    background-color: #fff;
    padding: 0.15rem 0;
    border: 1px solid #e7e9eb;
    @include setBorderRadius(0.25rem);
    box-shadow: 0 0 5px 1px rgba(52, 58, 64, 0.1);

    .menu_item {
        display: block;
        width: 100%;
        clear: both;
        font-weight: 400;
        color: #212529;
        text-align: inherit;
        white-space: nowrap;
        background-color: transparent;
        border: 0;
        padding: 10px 20px;
        font-size: 14px;
        @include setBorderRadius(0);
        transition: none;

        &:hover:not(.disabled) {
            background-color: rgba(64, 87, 109, 0.07);
            cursor: pointer;
        }

        &.disabled {
            color: #798488 !important;
            cursor: default;
        }

        &:focus:not(.disabled),
        &.focus:not(.disabled),
        &:active:not(.disabled),
        &.active:not(.disabled) {
            color: #282a35 !important;
            box-shadow: inset 0 0 0.3rem 0.1rem rgba(52, 58, 64, 0.1) !important;
            background-color: rgba(64, 87, 109, 0.07) !important;
        }

        &.red_text:not(.disabled) {
            color: #d9212c;

            &:focus,
            &.focus,
            &:active,
            &.active {
                color: #d9212c !important;
            }
        }
    }

    .divider {
        margin: 0.15rem 0;
        display: block;
        width: 100%;
        height: 1px;
        background-color: #e3e3e3;
    }
}
