@import "../../assets/styles/Colors.scss";

.banner_wrapper {
    width: 100%;
    height: 50px;
    background-color: $black;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    color: white;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.banner_link {
    text-decoration: none;
    color: white;
}

.banner_link:hover {
    text-decoration: underline;
    color: white;
}

.banner_btn {
    height: 22px;
    width: 50px;
    border-radius: 17px;
    background: $primary-green;
    cursor: pointer;
    padding: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    & > div {
        color: white;
        font-weight: 600;
        line-height: 16px;
        font-size: 13px;
        letter-spacing: 0.833333px;
    }

    &:focus,
    &:hover {
        background: $hover-green;
    }

    &:active {
        background: $pressed-green;
    }
}